import React from "react";
import Auth from "../Auth/Auth";
import Login from "../Auth/Login/Login";

const Home = () => {
    return (
    <>
        <h1>Home</h1>

    </>
)
};
export default Home;