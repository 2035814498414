import React from "react";

const Other = () => {
    return (
        <>
            <h1>Other</h1>
            <div>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorem sunt
                itaque, perferendis necessitatibus corrupti ea magni voluptatibus est
                ipsa. Nulla praesentium dolore eos alias incidunt, odio et eaque, sunt
                vitae corporis animi, itaque architecto. Sunt minima nostrum fugiat
                quibusdam voluptate dolore illo cupiditate quam odio sequi. Consequatur
                tempora ullam, ipsam molestias dolor dolores tempore. Molestias, error
                porro tenetur delectus numquam animi aut quod consequuntur? Dolor sequi
                quisquam ex, eaque explicabo, labore maxime veniam voluptas enim
                recusandae architecto est voluptates optio libero officiis consectetur
                possimus voluptate ipsam alias velit veritatis reprehenderit
                repudiandae. Ullam praesentium inventore minima eius dolorem omnis fugit
                facilis.
            </div>
            <br />
            <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus
                libero laboriosam quia totam, ducimus dolorum velit voluptates ipsam ad
                labore repellendus! Reprehenderit corporis labore totam est quas natus
                non quis? Atque porro obcaecati error hic perspiciatis itaque
                reprehenderit ut aperiam, ipsa, repellendus voluptatum, architecto sint
                delectus. Dolorem, officia nostrum? Quae similique eveniet, animi
                laborum impedit commodi, deleniti cum adipisci atque quidem culpa
                incidunt placeat id rem dolorum sit eligendi sapiente ea sed, voluptate
                voluptates minima pariatur ipsam delectus. Inventore deserunt
                exercitationem repudiandae ducimus nihil, assumenda vel ex optio aut
                voluptates nostrum dolor beatae in voluptatem. Adipisci excepturi
                nesciunt ab soluta.
            </div>
            <br />
            <div>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea aut a,
                expedita quaerat, facere iste earum deserunt numquam dignissimos
                obcaecati voluptatem repellendus nemo eveniet corporis esse voluptatibus
                facilis laboriosam natus quam sapiente ipsum ipsa, repudiandae qui
                alias. Ut inventore commodi quae ea excepturi, soluta distinctio
                laboriosam cum voluptatem consectetur cupiditate neque eius esse placeat
                consequatur assumenda, unde atque nemo modi perferendis? Saepe
                molestiae, earum at dicta sit iusto dignissimos vero voluptate voluptas
                qui ullam molestias omnis cupiditate dolores sunt incidunt ab nisi eaque
                culpa dolorum? Provident eos doloribus soluta maxime obcaecati quam, a,
                quod blanditiis dolores odit aliquid quis distinctio.
            </div>
            <br />
            <div>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea aut a,
                expedita quaerat, facere iste earum deserunt numquam dignissimos
                obcaecati voluptatem repellendus nemo eveniet corporis esse voluptatibus
                facilis laboriosam natus quam sapiente ipsum ipsa, repudiandae qui
                alias. Ut inventore commodi quae ea excepturi, soluta distinctio
                laboriosam cum voluptatem consectetur cupiditate neque eius esse placeat
                consequatur assumenda, unde atque nemo modi perferendis? Saepe
                molestiae, earum at dicta sit iusto dignissimos vero voluptate voluptas
                qui ullam molestias omnis cupiditate dolores sunt incidunt ab nisi eaque
                culpa dolorum? Provident eos doloribus soluta maxime obcaecati quam, a,
                quod blanditiis dolores odit aliquid quis distinctio.
            </div>
            <br />
            <div>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea aut a,
                expedita quaerat, facere iste earum deserunt numquam dignissimos
                obcaecati voluptatem repellendus nemo eveniet corporis esse voluptatibus
                facilis laboriosam natus quam sapiente ipsum ipsa, repudiandae qui
                alias. Ut inventore commodi quae ea excepturi, soluta distinctio
                laboriosam cum voluptatem consectetur cupiditate neque eius esse placeat
                consequatur assumenda, unde atque nemo modi perferendis? Saepe
                molestiae, earum at dicta sit iusto dignissimos vero voluptate voluptas
                qui ullam molestias omnis cupiditate dolores sunt incidunt ab nisi eaque
                culpa dolorum? Provident eos doloribus soluta maxime obcaecati quam, a,
                quod blanditiis dolores odit aliquid quis distinctio.
            </div>
            <br />
            <div>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea aut a,
                expedita quaerat, facere iste earum deserunt numquam dignissimos
                obcaecati voluptatem repellendus nemo eveniet corporis esse voluptatibus
                facilis laboriosam natus quam sapiente ipsum ipsa, repudiandae qui
                alias. Ut inventore commodi quae ea excepturi, soluta distinctio
                laboriosam cum voluptatem consectetur cupiditate neque eius esse placeat
                consequatur assumenda, unde atque nemo modi perferendis? Saepe
                molestiae, earum at dicta sit iusto dignissimos vero voluptate voluptas
                qui ullam molestias omnis cupiditate dolores sunt incidunt ab nisi eaque
                culpa dolorum? Provident eos doloribus soluta maxime obcaecati quam, a,
                quod blanditiis dolores odit aliquid quis distinctio.
            </div>
            <br />
            <div>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea aut a,
                expedita quaerat, facere iste earum deserunt numquam dignissimos
                obcaecati voluptatem repellendus nemo eveniet corporis esse voluptatibus
                facilis laboriosam natus quam sapiente ipsum ipsa, repudiandae qui
                alias. Ut inventore commodi quae ea excepturi, soluta distinctio
                laboriosam cum voluptatem consectetur cupiditate neque eius esse placeat
                consequatur assumenda, unde atque nemo modi perferendis? Saepe
                molestiae, earum at dicta sit iusto dignissimos vero voluptate voluptas
                qui ullam molestias omnis cupiditate dolores sunt incidunt ab nisi eaque
                culpa dolorum? Provident eos doloribus soluta maxime obcaecati quam, a,
                quod blanditiis dolores odit aliquid quis distinctio.
            </div>
        </>
    );
};
export default Other;